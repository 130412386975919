import React       from 'react';
import { graphql } from 'gatsby';
import { Box }     from '@chakra-ui/react';

import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia  from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Lightbox     from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import Center       from '@interness/web-core/src/components/structure/Center/Center';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';


const BauklempnereiPage = (props) => {
  return (
    <>
      <HeaderMedia id="header-bauklempnerei"/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle="Wir fügen zusammen, was zusammen gehört">Bauklempnerei / Zimmerei</Heading>
        <Box textAlign="justify">
          <p>Zum Umfang unserer Leistungen gehört die Montage aller zusammenhängenden Bauteile, wie z.B. Fallrohren,
            Kehlen, Dachrinnen und Regenentwässerungssystemen oberhalb des Erdreichs. Neuerstellung dieser Systeme,
            sowie
            die fachmännischen Reparatur beschädigter Bausysteme ist Bestandteil unserer Kernkompetenz. Die folgenden
            Materialien kommen dabei zum Einsatz: Zink, Kupfer und Aluminium. Unsere maschinellen Einrichtungen
            ermöglichen uns den Zuschnitt und die Anpassung von Metallblechen und Scharen, zur Verkleidung an: Dächern,
            Fassaden, Gauben und Kaminen mit Zink – Kupfer – Edelstahl oder Aluminium – Blechen sowie die Herstellung
            von:
            Dachkehlen, Dachrand-Abschlüssen, Untersichten und Sichtblenden,optisch ansprechend in Form und Farbe nach
            Wahl, passend zu Ihrem Bauvorhaben.
            Blecharbeiten wie Kantungen, Stehfalzdeckung, Einfassungen, Anschlüsse, Abflüsse und Dachrinnen bei Flach-
            und
            geneigten Dächern längst Standard im Dachdeckerhandwerk. So entstehen aus Kupfer, Titanzink, Aluminium, aber
            auch aus Stahl- und Edelstahlblechen edle, matt schimmernde Bauteile an Dächern und Fassaden.
            <br/><br/>
            Wünschen Sie einen Neubau oder einen Umbau arbeiten wir auch eng mit einer Zimmerei zusammen, um Ihnen einen
            neuen Dachstuhl anzufertigen.
          </p>
        </Box>
        <Heading tag="h2">Übersicht Bauklempnerei / Zimmerei</Heading>
        <Lightbox images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} fromApi/>
        <Spacer/>
        <Center>
          <CallToAction/>
        </Center>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default BauklempnereiPage;

export const query = graphql`
    query {
        gallery: directusMediaCollection(name: {eq: "bauklempnerei-gallery"}) {
            ...LightboxGalleryGrid
        }
    }
`;